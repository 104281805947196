var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2 w-100 d-flex justify-content-center"},[_c('b-card',{staticClass:"w-75"},[_c('ValidationObserver',{ref:"validationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-form-group',{attrs:{"label":"Model name *","label-for":"v-model-name"}},[_c('validation-provider',{attrs:{"vid":"modelName","name":"Model name","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-model-name","placeholder":"Model name","state":errors.length > 0 ? false:null,"debounce":"275"},on:{"update":_vm.onUpdateModelName,"focusout":_vm.validate},model:{value:(_vm.modelName),callback:function ($$v) {_vm.modelName=$$v},expression:"modelName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Model key *","label-for":"v-model-key"}},[_c('validation-provider',{ref:"vpModelKey",attrs:{"vid":"modelKey","name":"Model key","rules":"required|alpha-num|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-model-key","placeholder":"Model key","formatter":_vm.modelKeyFormatter,"debounce":"275","minlength":"2","maxlength":"10"},on:{"update":_vm.validate,"focusout":_vm.validate},model:{value:(_vm.modelKey),callback:function ($$v) {_vm.modelKey=$$v},expression:"modelKey"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Model description","label-for":"v-model-description"}},[_c('b-form-textarea',{attrs:{"id":"v-model-description","placeholder":"Model description","rows":"4"},model:{value:(_vm.modelDescription),callback:function ($$v) {_vm.modelDescription=$$v},expression:"modelDescription"}})],1),_c('b-form-group',{attrs:{"label":"Model scope","label-for":"v-model-scope"}},[_c('b-form-textarea',{attrs:{"id":"v-model-scope","placeholder":"Model scope","rows":"4"},model:{value:(_vm.modelScope),callback:function ($$v) {_vm.modelScope=$$v},expression:"modelScope"}})],1),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"reset","variant":"outline-secondary","disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.onReset.apply(null, arguments)}}},[_vm._v(" Reset ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"success","disabled":invalid || _vm.loading}},[(_vm.loading)?_c('span',[_c('b-spinner',{attrs:{"small":"","type":"grow"}}),_vm._v(" Creating... ")],1):_c('span',[_vm._v(" Create Model ")])])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }